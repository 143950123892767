.loginBox {
  width: 20rem;
}

.logoText {
  color: black;
  font-family: 'BMHannaPro';
  font-size: xx-large;
}
.previewImage {
  object-fit: contain;
  width: 240px;
  height: 160px;
}
